$(window).on("resize", function () {
  var win = $(this);
  if (win.width() < 900) {
    $(".nav-item").removeClass("dropdown-hover");
  }
});

$(document).ready(function () {
  if (screen.width < 900) {
    $(".nav-item").removeClass("dropdown-hover");
  }

  $(".nav-button").on("click", function () {
    $(".nav-button").toggleClass("change");
  });
});

var isSafari = window.safari !== undefined;
if (isSafari) {
  $(".nav-button").addClass("change1");
}
